.societario {
  .modal-dialog {
    .modal-header {
      .close {
        color: var(--primary-base);
      .icon-modal{
        width: 32px;
        height: 32px;
      }
      }     
      color: var(--primary-base);
      background-color: white; 
    }
  }
}

.react-select__loading-indicator {
  color: var(--primary-base) !important;
}

.is-invalid > .react-select__control {
  border-color: var(--bs-danger) !important;
}
.icon-solucao {
  svg{
    width: 24px !important;
    height: 24px!important;
  }
}

.progress-bar-orange {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: var(--primary-base);
  transition: width .6s ease;
}

