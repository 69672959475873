.dashboard-conteiner{
    display: flex;
    flex-wrap: wrap;
    min-height: 25vh;
    width: 100%;
    height: fit-content;
    background-color: #f5f5f5;
    margin: 0;
    padding: 1rem .25rem 1rem .25rem;
    position: relative;

    .sem-widgets{
        display: flex;
        min-height: 100%;
        -webkit-user-select: none;
        user-select: none;
        margin: 0 auto;
        flex-direction: column;
        justify-content: center;
        opacity: .75;
        font-weight: 600;
        text-shadow: #00000038 5px 4px 3px;
    }

    &.carregando{
        user-select: none;
        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(2px);
        }
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.5rem;
            color: #000;
            z-index: 100;
            width: 200px;
            height: 200px;
            background-image: url('../../../../../public/images/logo-xjur.png');
            background-size: cover;
            animation: heartBeat 1s infinite;
        }
    }

    .widget{
        // max-height: 300px;
        padding: .25rem;
        box-sizing: border-box;
        cursor: pointer; 

        &.widget-desativado{
            display: none;
            opacity: .5;
            order: 50000;
        }

        @media (-webkit-device-pixel-ratio: 1.25) {
            * {
              zoom: 2;
            }
        }

        .widget-reordenar{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1rem;
            z-index: 100;
            display: flex;
            display: none;
            justify-content: center;
            div{
                font-size: 1rem;
                margin: 0 .25rem;
                color: #0008;
                z-index: 10000;
                &:hover{
                    color: #000;
                }
            }
            
        }

        &:first-of-type{
            .widget-reordenar{
                div:first-of-type{
                    display: none;
                }
            }
        }

        &:last-of-type{
            .widget-reordenar{
                div:last-of-type{
                    display: none;
                }
            }
        }

        .widget-redimensionar{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            border: dashed 1px #000;
            z-index: 100;
            .puxador{
                position: absolute;
                display: none;
                top:0;
                right: 0;
                bottom: 0;
                width: .5rem;
                opacity: .5;
                background-color: #0008;
                z-index: 1000;
                cursor: col-resize;
                [draggable=true] {
                    cursor: col-resize;
                }
                &:hover{
                    opacity: 1;
                }
            }
            &:hover{
                .puxador{
                    display: block;
                }
            }
        }

        &.widget-desativado {
            .widget-reordenar{
                display: none;
            }
            .widget-redimensionar{
                display: none;
            }
        }
        
        &.col{
            min-width: 25%;
        }

        .widget-backdrop{
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.5);
            z-index: 1;
            &.visivel{
                display: block;
            }
        }
        
        .widget-conteiner{
            background-color: #fff;
            border-radius: .5rem;
            padding: 1rem;
            box-shadow: 0 0.25rem 1rem rgba(0,0,0,.05);
            width: 100%;
            min-height: 100%;
            // max-height: 300px;
            display: flex;
            flex-direction: column;
            transform-origin: left top;
            transition: transform .3s ease-in-out;
            position: relative;


            .widget-titulo{
                font-size: 1rem;
                font-weight: 600;
            }
            .widget-conteudo{
                position: relative;
                font-size: .75rem;
                font-weight: 400;
                // max-height: calc(300px - 2.5rem);
                flex:1;
                display: flex;
                flex-wrap: wrap;
                

                .widget-card-conteiner{
                    display: flex;
                    width: 100%;
                    min-height: 100%;
                    flex: 1;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-top: 1rem;

                    >.widget-card{
                        min-width: 20%;
                        padding: .1rem;
                        max-height: 100%;
                        .card{
                            height: 100%;
                            border-radius: 0;
                            user-select: none;

                            &:hover{    
                                .card-body{
                                    .icone:not(.estatico){
                                        svg{
                                            transform: scale(1.2);
                                        }
                                        i{
                                            @extend svg;
                                        }
                                    }
                                }
                            }
                            .card-body{
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                height: 100%;
                                padding: 0!important;
                                >*:first-child{
                                    padding-top: .25rem;
                                }
                                >*:last-child{
                                    padding-bottom: .25rem;
                                }
                                .icone{
                                    text-align: center!important;
                                    font-size: 1.5rem;
                                    height: 50%;
                                    font-weight: bolder;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    font-size: 1.5rem;
                                    padding:0;
                                    svg{
                                        stroke: currentcolor;
                                        fill: currentColor;
                                        margin: 0 auto;
                                        transition: all .2s ease-in-out;
                                        path{
                                            stroke: currentcolor;
                                        }
                                    }
                                    i{
                                        @extend svg;
                                    }
                                }.etiqueta{
                                    font-size: .9rem;
                                    text-align: center;
                                    font-weight: 600;
                                    user-select: text;
                                }
                                .valor{
                                    white-space: nowrap!important;
                                    text-align: center!important;
                                    font-size: 1.5rem;
                                    flex:1;
                                    font-weight: bolder;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    user-select: text;
                                }
                            }
                        }
                        &.compacto{
                            .card{

                                .card-body{
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    .etiqueta{
                                        order: 1;
                                        width: 100%;
                                        height: fit-content;
                                    }
                                    .icone{
                                        order: 2;
                                        width: fit-content;
                                        padding: 0 1rem;
                                        font-size: 2rem;
                                    }
                                    .valor{
                                        order: 3;
                                        width: 50%;
                                        font-size: 1.75rem;
                                    }
                                }
                            }
                        }
                    }
                }

                &>.widget-erro{
                    width: 100%;
                    text-align: center;
                    line-height: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    color: #f44336;
                }
            }
        }
        
        &.unico{
            .widget-conteiner{
                padding: 0rem;
                background-color: transparent;
                .widget-card-conteiner{
                    padding-top: 0;

                    .widget-card{
                        padding: 0;
                        .card{
                            border-radius: .5rem;
                            overflow: hidden;
                            .card-body{
                                .etiqueta{
                                    font-size: 1rem;
                                    font-weight: 600;
                                }
                                >*:first-child{
                                    padding-top: 0;
                                }
                                >*:last-child{
                                    padding-bottom: 0;
                                }
                            }
                        }
                        &.compacto{
                            .card{

                                .card-body{
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    .etiqueta{
                                        order: 1;
                                        width: 100%;
                                        height: fit-content;
                                    }
                                    .icone{
                                        order: 2;
                                        width: fit-content;
                                        padding: 0 3rem;
                                        font-size: 2rem;
                                        height: 100%;
                                    }
                                    .valor{
                                        order: 3;
                                        width: 50%;
                                        font-size: 1.75rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        

        &.arrastando{
            .widget-conteiner{
                box-shadow: #00000052 0px 0px 30px 0px;
                //outline: #0b0b0b;
                //outline-style: dotted;
                //outline-offset: 2px;
                //outline-width: .25em;

                .widget-redimensionar{
                    display: none;
                }
            }
        }
    }

    &.editando:not(.carregando){
        .widget-desativado{
            display: block;
        }
    }
}