@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: var(--primary-base);
$submenu-bg-color: rgba(#000, 0.09);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: white;
$highlight-color: white;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Roboto', sans-serif;
  background-color: $sidebar-bg-color;
}


.form-check-input:checked {
  background-color: var(--primary-base2) !important;
  border: 1.5px solid var(--primary-base2);
  color: var(--primary-base2);
  cursor: pointer;
}

.form-control-error {
  border: 1.5px solid red;
}

.codigo-value {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
}

.report-container {
  width: 1000px;
  /* Largura desejada */
  height: 500px;
  /* Altura desejada */
  margin: 0 auto;
  /* Centraliza o iframe horizontalmente na div pai */
}

/* Estilo quando estiver em tela cheia */
.report-container-fullscreen {
  width: 100%;
  /* Largura 100% para preencher toda a largura da tela */
  height: 100vh;
  /* Altura 100vh para preencher toda a altura da tela */
}

.progress-validation {
  background-color: var(--primary-base-light2);
  border-color: red($color: #000000);
}

#root {
  height: 100%;
}

.fv-plugins-message-container {
  font-size: 10px;
  color: #d63030;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;

      span {
        margin-left: 5px;
        font-size: 13px;
      }

      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;

      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;

      a {
        text-decoration: none;
        color: #888;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-bagdes {
    margin-top: 10px;

    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;

    >span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }

    .block {
      direction: rtl;

      >span {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;

    header {
      h1 {
        font-size: 24px;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}


// publicações
.lidoStatus {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #2ecc71;
  animation: pulse 2.0s infinite;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}


// publicações
.lidoExecutando {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: yellow;
  animation: pulse 2.0s infinite;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.pendenteStatus {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
  animation: pulse 2.0s infinite;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.excluidaStatus {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgba(88, 79, 79, 0.425);
  animation: pulse 2.0s infinite;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
    /* Increase the scaling factor for a larger pulse */
  }
}

