@font-face {
  font-family: "ArialRegular";
  src: local("ArialRegular"),
    url("./fonts/Arial/arial.ttf") format("truetype");
}

@font-face {
  font-family: "ArialBold";
  src: local("ArialBold"),
    url("./fonts/Arial/arial-bold.ttf") format("truetype");
}

@font-face {
  font-family: "ArialBoldItalic";
  src: local("ArialBoldItalic"),
    url("./fonts/Arial/arial-bold-italic.ttf") format("truetype");
}

@font-face {
  font-family: "ArialBlack";
  src: local("ArialBlack"),
    url("./fonts/Arial/arial-black.ttf") format("truetype");
}

@font-face {
  font-family: "ArialBlackItalic";
  src: local("ArialBlackItalic"),
    url("./fonts/Arial/arial-bold-italic.ttf") format("truetype");
}

@font-face {
  font-family: "ArialItalic";
  src: local("ArialItalic"),
    url("./fonts/Arial/arial-italic.ttf") format("truetype");
}

@font-face {
  font-family: "CalibriLight";
  src: local("CalibriLight"),
    url("./fonts/Calibri/CalibriLight.ttf") format("truetype");
}

@font-face {
  font-family: "CalibriRegular";
  src: local("CalibriRegular"),
    url("./fonts/Calibri/CalibriRegular.ttf") format("truetype");
}

@font-face {
  font-family: "CalibriBoldItalic";
  src: local("CalibriBoldItalic"),
    url("./fonts/Calibri/CalibriBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "CalibriLightItalic";
  src: local("CalibriLightItalic"),
    url("./fonts/Calibri/CalibriLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "CaveatRegular";
  src: local("CaveatRegular"),
    url("./fonts/Caveat/Caveat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CaveatMedium";
  src: local("CaveatMedium"),
    url("./fonts/Caveat/Caveat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "CaveatBold";
  src: local("CaveatBold"),
    url("./fonts/Caveat/Caveat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "CaveatSemiBold";
  src: local("CaveatSemiBold"),
    url("./fonts/Caveat/Caveat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "ComfortaaLight";
  src: local("ComfortaaLight"),
    url("./fonts/Comfortaa/Comfortaa-Light.ttf") format("truetype");
}

@font-face {
  font-family: "ComfortaaRegular";
  src: local("ComfortaaRegular"),
    url("./fonts/Comfortaa/Comfortaa-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ComfortaaMedium";
  src: local("ComfortaaMedium"),
    url("./fonts/Comfortaa/Comfortaa-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "ComfortaaSemiBold";
  src: local("ComfortaaSemiBold"),
    url("./fonts/Comfortaa/Comfortaa-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "ComfortaaBold";
  src: local("ComfortaaBold"),
    url("./fonts/Comfortaa/Comfortaa-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ComicSansMSRegular";
  src: local("ComicSansMSRegular"),
    url("./fonts/ComicSansMs/comic-sans-ms-4.ttf") format("truetype");
}

@font-face {
  font-family: "ComicSansMSItalic";
  src: local("ComicSansMSItalic"),
    url("./fonts/ComicSansMs/comic-sans-ms-italic.ttf") format("truetype");
}

@font-face {
  font-family: "ComicSansMSBold";
  src: local("ComicSansMSBold"),
    url("./fonts/ComicSansMs/comic-sans-ms-bold-2.ttf") format("truetype");
}

@font-face {
  font-family: "ComicSansMSBoldItalic";
  src: local("ComicSansMSBoldItalic"),
    url("./fonts/ComicSansMs/comic-sans-ms-bold-italic.ttf") format("truetype");
}

@font-face {
  font-family: "CourierNewRegular";
  src: local("CourierNewRegular"),
    url("./fonts/CourierNew/courier-new.ttf") format("truetype");
}

@font-face {
  font-family: "CourierNewBold";
  src: local("CourierNewBold"),
    url("./fonts/CourierNew/courier-new-bold.ttf") format("truetype");
}

@font-face {
  font-family: "CourierNewBoldItalic";
  src: local("CourierNewBoldItalic"),
    url("./fonts/CourierNew/courier-new-bold-italic.ttf") format("truetype");
}

@font-face {
  font-family: "CourierNewItalic";
  src: local("CourierNewItalic"),
    url("./fonts/CourierNew/courier-10-bt-italic.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondRegular";
  src: local("EbGaramondRegular"),
    url("./fonts/EB_Garamond/EBGaramond-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondMedium";
  src: local("EbGaramondMedium"),
    url("./fonts/EB_Garamond/EBGaramond-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondMediumItalic";
  src: local("EbGaramondMediumItalic"),
    url("./fonts/EB_Garamond/EBGaramond-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondItalic";
  src: local("EbGaramondItalic"),
    url("./fonts/EB_Garamond/EBGaramond-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondBold";
  src: local("EbGaramondBold"),
    url("./fonts/EB_Garamond/EBGaramond-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondBoldItalic";
  src: local("EbGaramondBoldItalic"),
    url("./fonts/EB_Garamond/EBGaramond-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondExtraBold";
  src: local("EbGaramondExtraBold"),
    url("./fonts/EB_Garamond/EBGaramond-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondExtraBoldItalic";
  src: local("EbGaramondExtraBoldItalic"),
    url("./fonts/EB_Garamond/EBGaramond-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondSemiBold";
  src: local("EbGaramondSemiBold"),
    url("./fonts/EB_Garamond/EBGaramond-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "EbGaramondSemiBoldItalic";
  src: local("EbGaramondSemiBoldItalic"),
    url("./fonts/EB_Garamond/EBGaramond-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "GeorgiaRegular";
  src: local("GeorgiaRegular"),
    url("./fonts/Georgia/georgia-4.ttf") format("truetype");
}

@font-face {
  font-family: "GeorgiaItalic";
  src: local("GeorgiaItalic"),
    url("./fonts/Georgia/georgia-italic.ttf") format("truetype");
}

@font-face {
  font-family: "GeorgiaBold";
  src: local("GeorgiaBold"),
    url("./fonts/Georgia/georgia-bold-2.ttf") format("truetype");
}

@font-face {
  font-family: "GeorgiaBoldItalic";
  src: local("GeorgiaBoldItalic"),
    url("./fonts/Georgia/georgia-bold-italic.ttf") format("truetype");
}

@font-face {
  font-family: "LexendLight";
  src: local("LexendLight"),
    url("./fonts/Lexend/Lexend-Light.ttf") format("truetype");
}

@font-face {
  font-family: "LexendExtraLight";
  src: local("LexendExtraLight"),
    url("./fonts/Lexend/Lexend-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "LexendRegular";
  src: local("LexendRegular"),
    url("./fonts/Lexend/Lexend-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LexendMedium";
  src: local("LexendMedium"),
    url("./fonts/Lexend/Lexend-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "LexendBold";
  src: local("LexendBold"),
    url("./fonts/Lexend/Lexend-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LexendExtraBold";
  src: local("LexenLexendExtraBolddBold"),
    url("./fonts/Lexend/Lexend-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "LexendSemiBold";
  src: local("LexendSemiBold"),
    url("./fonts/Lexend/Lexend-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "LexendBlack";
  src: local("LexendBlack"),
    url("./fonts/Lexend/Lexend-Black.ttf") format("truetype");
}

@font-face {
  font-family: "LobsterRegular";
  src: local("LobsterRegular"),
    url("./fonts/Lobster/Lobster-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LobsterItalic";
  src: local("LobsterItalic"),
    url("./fonts/Lobster/lobster-two-italic.ttf") format("truetype");
}

@font-face {
  font-family: "LobsterBold";
  src: local("LobsterBold"),
    url("./fonts/Lobster/lobster-two-bold.ttf") format("truetype");
}

@font-face {
  font-family: "LobsterBoldItalic";
  src: local("LobsterBoldItalic"),
    url("./fonts/Lobster/lobster-two-bold-italic.ttf") format("truetype");
}

@font-face {
  font-family: "LoraRegular";
  src: local("LoraRegular"),
    url("./fonts/Lora/Lora-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LoraItalic";
  src: local("LoraItalic"),
    url("./fonts/Lora/Lora-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "LoraMedium";
  src: local("LoraMedium"),
    url("./fonts/Lora/Lora-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "LoraMediumItalic";
  src: local("LoraMediumItalic"),
    url("./fonts/Lora/Lora-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LoraBold";
  src: local("LoraBold"),
    url("./fonts/Lora/Lora-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LoraBoldItalic";
  src: local("LoraBoldItalic"),
    url("./fonts/Lora/Lora-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LoraSemiBold";
  src: local("LoraSemiBold"),
    url("./fonts/Lora/Lora-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "LoraSemiBoldItalic";
  src: local("LoraSemiBoldItalic"),
    url("./fonts/Lora/Lora-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MerriweatherLight";
  src: local("MerriweatherLight"),
    url("./fonts/Merriweather/Merriweather-Light.ttf") format("truetype");
}

@font-face {
  font-family: "MerriweatherLightItalic";
  src: local("MerriweatherLightItalic"),
    url("./fonts/Merriweather/Merriweather-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MerriweatherRegular";
  src: local("MerriweatherRegular"),
    url("./fonts/Merriweather/Merriweather-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MerriweatherItalic";
  src: local("MerriweatherItalic"),
    url("./fonts/Merriweather/Merriweather-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "MerriweatherBold";
  src: local("MerriweatherBold"),
    url("./fonts/Merriweather/Merriweather-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "MerriweatherBoldItalic";
  src: local("MerriweatherBoldItalic"),
    url("./fonts/Merriweather/Merriweather-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MerriweatherBlack";
  src: local("MerriweatherBlack"),
    url("./fonts/Merriweather/Merriweather-Black.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratThin";
  src: local("MontserratThin"),
    url("./fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratThinItalic";
  src: local("MontserratThinItalic"),
    url("./fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratExtraLight";
  src: local("MontserratExtraLight"),
    url("./fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratExtraLightItalic";
  src: local("MontserratExtraLightItalic"),
    url("./fonts/Montserrat/Montserrat-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratLight";
  src: local("MontserratLight"),
    url("./fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratLightItalic";
  src: local("MontserratLightItalic"),
    url("./fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratItalic";
  src: local("MontserratItalic"),
    url("./fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
    url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("./fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratMediumItalic";
  src: local("MontserratMediumItalic"),
    url("./fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratSemiBoldItalic";
  src: local("MontserratSemiBoldItalic"),
    url("./fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBoldItalic";
  src: local("MontserratBoldItalic"),
    url("./fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratExtraBold";
  src: local("MontserratExtraBold"),
    url("./fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratExtraBoldItalic";
  src: local("MontserratExtraBoldItalic"),
    url("./fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBlack";
  src: local("MontserratBlack"),
    url("./fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBlackItalic";
  src: local("MontserratBlackItalic"),
    url("./fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoExtraLight";
  src: local("NunitoExtraLight"),
    url("./fonts/Nunito/Nunito-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoExtraLightItalic";
  src: local("NunitoExtraLightItalic"),
    url("./fonts/Nunito/Nunito-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoLight";
  src: local("NunitoLight"),
    url("./fonts/Nunito/Nunito-Light.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoLightItalic";
  src: local("NunitoLightItalic"),
    url("./fonts/Nunito/Nunito-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoRegular";
  src: local("NunitoRegular"),
    url("./fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoMedium";
  src: local("NunitoMedium"),
    url("./fonts/Nunito/Nunito-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoMediumItalic";
  src: local("NunitoMediumItalic"),
    url("./fonts/Nunito/Nunito-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSemiBold";
  src: local("NunitoSemiBold"),
    url("./fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSemiBoldItalic";
  src: local("NunitoSemiBoldItalic"),
    url("./fonts/Nunito/Nunito-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoBold";
  src: local("NunitoBold"),
    url("./fonts/Nunito/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoBoldItalic";
  src: local("NunitoBoldItalic"),
    url("./fonts/Nunito/Nunito-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoExtraBold";
  src: local("NunitoExtraBold"),
    url("./fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoExtraBoldItalic";
  src: local("NunitoExtraBoldItalic"),
    url("./fonts/Nunito/Nunito-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoBlack";
  src: local("NunitoBlack"),
    url("./fonts/Nunito/Nunito-Black.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoBlackItalic";
  src: local("NunitoBlackItalic"),
    url("./fonts/Nunito/Nunito-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoItalic";
  src: local("NunitoItalic"),
    url("./fonts/Nunito/Nunito-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "OswaldExtraLight";
  src: local("OswaldExtraLight"),
    url("./fonts/Oswald/Oswald-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "OswaldLight";
  src: local("OswaldLight"),
    url("./fonts/Oswald/Oswald-Light.ttf") format("truetype");
}

@font-face {
  font-family: "OswaldRegular";
  src: local("OswaldRegular"),
    url("./fonts/Oswald/Oswald-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OswaldMedium";
  src: local("OswaldMedium"),
    url("./fonts/Oswald/Oswald-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "OswaldSemiBold";
  src: local("OswaldSemiBold"),
    url("./fonts/Oswald/Oswald-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "OswaldBold";
  src: local("OswaldBold"),
    url("./fonts/Oswald/Oswald-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PacificoRegular";
  src: local("PacificoRegular"),
    url("./fonts/Pacifico/pacifico-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pacifico";
  src: local("Pacifico"),
    url("./fonts/Pacifico/pacifico.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayRegular";
  src: local("PlayfairDisplayRegular"),
    url("./fonts/Playfair_Display/PlayfairDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayMedium";
  src: local("PlayfairDisplayMedium"),
    url("./fonts/Playfair_Display/PlayfairDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayMediumItalic";
  src: local("PlayfairDisplayMediumItalic"),
    url("./fonts/Playfair_Display/PlayfairDisplay-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplaySemiBold";
  src: local("PlayfairDisplaySemiBold"),
    url("./fonts/Playfair_Display/PlayfairDisplay-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplaySemiBoldItalic";
  src: local("PlayfairDisplaySemiBoldItalic"),
    url("./fonts/Playfair_Display/PlayfairDisplay-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayBold";
  src: local("PlayfairDisplayBold"),
    url("./fonts/Playfair_Display/PlayfairDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayBoldItalic";
  src: local("PlayfairDisplayBoldItalic"),
    url("./fonts/Playfair_Display/PlayfairDisplay-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayExtraBold";
  src: local("PlayfairDisplayExtraBold"),
    url("./fonts/Playfair_Display/PlayfairDisplay-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayExtraBoldItalic";
  src: local("PlayfairDisplayExtraBoldItalic"),
    url("./fonts/Playfair_Display/PlayfairDisplay-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayBlack";
  src: local("PlayfairDisplayBlack"),
    url("./fonts/Playfair_Display/PlayfairDisplay-Black.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayBlackItalic";
  src: local("PlayfairDisplayBlackItalic"),
    url("./fonts/Playfair_Display/PlayfairDisplay-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "PlayfairDisplayItalic";
  src: local("PlayfairDisplayItalic"),
    url("./fonts/Playfair_Display/PlayfairDisplay-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifThin";
  src: local("RobotoSerifThin"),
    url("./fonts/Roboto_Serif/RobotoSerif-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifThinItalic";
  src: local("RobotoSerifThinItalic"),
    url("./fonts/Roboto_Serif/RobotoSerif-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifExtraLight";
  src: local("RobotoSerifExtraLight"),
    url("./fonts/Roboto_Serif/RobotoSerif-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifExtraLightItalic";
  src: local("RobotoSerifExtraLightItalic"),
    url("./fonts/Roboto_Serif/RobotoSerif-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifLight";
  src: local("RobotoSerifLight"),
    url("./fonts/Roboto_Serif/RobotoSerif-Light.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifLightItalic";
  src: local("RobotoSerifLightItalic"),
    url("./fonts/Roboto_Serif/RobotoSerif-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifRegular";
  src: local("RobotoSerifRegular"),
    url("./fonts/Roboto_Serif/RobotoSerif-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifMedium";
  src: local("RobotoSerifMedium"),
    url("./fonts/Roboto_Serif/RobotoSerif-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifMediumItalic";
  src: local("RobotoSerifMediumItalic"),
    url("./fonts/Roboto_Serif/RobotoSerif-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifSemiBold";
  src: local("RobotoSerifSemiBold"),
    url("./fonts/Roboto_Serif/RobotoSerif-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifSemiBoldItalic";
  src: local("RobotoSerifSemiBoldItalic"),
    url("./fonts/Roboto_Serif/RobotoSerif-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifBold";
  src: local("RobotoSerifBold"),
    url("./fonts/Roboto_Serif/RobotoSerif-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifBoldItalic";
  src: local("RobotoSerifBoldItalic"),
    url("./fonts/Roboto_Serif/RobotoSerif-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifExtraBold";
  src: local("RobotoSerifExtraBold"),
    url("./fonts/Roboto_Serif/RobotoSerif-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifExtraBoldItalic";
  src: local("RobotoSerifExtraBoldItalic"),
    url("./fonts/Roboto_Serif/RobotoSerif-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifBlack";
  src: local("RobotoSerifBlack"),
    url("./fonts/Roboto_Serif/RobotoSerif-Black.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifBlackItalic";
  src: local("RobotoSerifBlackItalic"),
    url("./fonts/Roboto_Serif/RobotoSerif-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoSerifItalic";
  src: local("RobotoSerifBlaRobotoSerifItalicckItalic"),
    url("./fonts/Roboto_Serif/RobotoSerif-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoThin";
  src: local("RobotoThin"),
    url("./fonts/Roboto/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoThinItalic";
  src: local("RobotoThinItalic"),
    url("./fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"),
    url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoLightItalic";
  src: local("RobotoLightItalic"),
    url("./fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
    url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMedium";
  src: local("RobotoMedium"),
    url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMediumItalic";
  src: local("RobotoMediumItalic"),
    url("./fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
    url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBoldItalic";
  src: local("RobotoBoldItalic"),
    url("./fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBlack";
  src: local("RobotoBlack"),
    url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBlackItalic";
  src: local("RobotoBlackItalic"),
    url("./fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoItalic";
  src: local("RobotoItalic"),
    url("./fonts/Roboto/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoThin";
  src: local("RobotoMonoThin"),
    url("./fonts/Roboto_Mono/RobotoMono-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoThinItalic";
  src: local("RobotoMonoThinItalic"),
    url("./fonts/Roboto_Mono/RobotoMono-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoExtraLight";
  src: local("RobotoMonoExtraLight"),
    url("./fonts/Roboto_Mono/RobotoMono-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoExtraLightItalic";
  src: local("RobotoMonoExtraLightItalic"),
    url("./fonts/Roboto_Mono/RobotoMono-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoLight";
  src: local("RobotoMonoLight"),
    url("./fonts/Roboto_Mono/RobotoMono-Light.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoLightItalic";
  src: local("RobotoMonoLightItalic"),
    url("./fonts/Roboto_Mono/RobotoMono-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoRegular";
  src: local("RobotoMonoRegular"),
    url("./fonts/Roboto_Mono/RobotoMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoMedium";
  src: local("RobotoMonoMedium"),
    url("./fonts/Roboto_Mono/RobotoMono-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoMediumItalic";
  src: local("RobotoMonoMediumItalic"),
    url("./fonts/Roboto_Mono/RobotoMono-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoMediumSemiBold";
  src: local("RobotoMonoMediumSemiBold"),
    url("./fonts/Roboto_Mono/RobotoMono-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoMediumSemiBoldItalic";
  src: local("RobotoMonoMediumSemiBoldItalic"),
    url("./fonts/Roboto_Mono/RobotoMono-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoMediumBold";
  src: local("RobotoMonoMediumBold"),
    url("./fonts/Roboto_Mono/RobotoMono-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoMediumBoldItalic";
  src: local("RobotoMonoMediumBoldItalic"),
    url("./fonts/Roboto_Mono/RobotoMono-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoMonoMediumItalic";
  src: local("RobotoMonoMediumItalic"),
    url("./fonts/Roboto_Mono/RobotoMono-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralExtraLight";
  src: local("SpectralExtraLight"),
    url("./fonts/Spectral/Spectral-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralExtraLightItalic";
  src: local("SpectralExtraLightItalic"),
    url("./fonts/Spectral/Spectral-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralLight";
  src: local("SpectralLight"),
    url("./fonts/Spectral/Spectral-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralLightItalic";
  src: local("SpectralLightItalic"),
    url("./fonts/Spectral/Spectral-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralRegular";
  src: local("SpectralRegular"),
    url("./fonts/Spectral/Spectral-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralMedium";
  src: local("SpectralMedium"),
    url("./fonts/Spectral/Spectral-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralMediumItalic";
  src: local("SpectralMediumItalic"),
    url("./fonts/Spectral/Spectral-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralSemiBold";
  src: local("SpectralSemiBold"),
    url("./fonts/Spectral/Spectral-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralSemiBoldItalic";
  src: local("SpectralSemiBoldItalic"),
    url("./fonts/Spectral/Spectral-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralBold";
  src: local("SpectralBold"),
    url("./fonts/Spectral/Spectral-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralBoldItalic";
  src: local("SpectralBoldItalic"),
    url("./fonts/Spectral/Spectral-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralExtraBold";
  src: local("SpectralExtraBold"),
    url("./fonts/Spectral/Spectral-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralExtraBoldItalic";
  src: local("SpectralExtraBoldItalic"),
    url("./fonts/Spectral/Spectral-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SpectralItalic";
  src: local("SpectralItalic"),
    url("./fonts/Spectral/Spectral-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "TimesRegular";
  src: local("TimesRegular"),
    url("./fonts/Times/times.ttf") format("truetype");
}

@font-face {
  font-family: "TimesItalic";
  src: local("TimesItalic"),
    url("./fonts/Times/times-10-italic.otf") format("truetype");
}

@font-face {
  font-family: "TimesBoldItalic";
  src: local("TimesBoldItalic"),
    url("./fonts/Times/times-10-bold-italic.otf") format("truetype");
}

@font-face {
  font-family: "TimesBold";
  src: local("TimesBold"),
    url("./fonts/Times/times-10-bold.otf") format("truetype");
}

@font-face {
  font-family: "TimesNewRomanRegular";
  src: local("TimesNewRomanRegular"),
    url("./fonts/TimesNewRoman/times-new-roman-14.ttf") format("truetype");
}

@font-face {
  font-family: "TimesNewRomanItalic";
  src: local("TimesNewRomanItalic"),
    url("./fonts/TimesNewRoman/times-new-roman-ce-italic.ttf") format("truetype");
}

@font-face {
  font-family: "TimesNewRomanBold";
  src: local("TimesNewRomanBold"),
    url("./fonts/TimesNewRoman/times-new-roman-bold.otf") format("truetype");
}

@font-face {
  font-family: "TimesNewRomanBoldItalic";
  src: local("TimesNewRomanBoldItalic"),
    url("./fonts/TimesNewRoman/times-new-roman-bold-italic.ttf") format("truetype");
}

@font-face {
  font-family: "TrebuchetRegular";
  src: local("Trebuchet"),
    url("./fonts/TrebuchetMS/trebuchet-ms.ttf") format("truetype");
}

@font-face {
  font-family: "TrebuchetMSRegular";
  src: local("TrebuchetMS"),
    url("./fonts/TrebuchetMS/trebuchet-ms.ttf") format("truetype");
}

@font-face {
  font-family: "TrebuchetItalic";
  src: local("TrebuchetItalic"),
    url("./fonts/TrebuchetMS/trebuchet-ms-italic.ttf") format("truetype");
}

@font-face {
  font-family: "TrebuchetBold";
  src: local("TrebuchetBold"),
    url("./fonts/TrebuchetMS/trebuchet-ms-bold.ttf") format("truetype");
}

@font-face {
  font-family: "TrebuchetBoldItalic";
  src: local("TrebuchetBoldItalic"),
    url("./fonts/TrebuchetMS/trebuchet-ms-bold-italic.ttf") format("truetype");
}

@font-face {
  font-family: "VerdanaRegular";
  src: local("Verdana"),
    url("./fonts/Verdana/verdana-4.ttf") format("truetype");
}

@font-face {
  font-family: "VerdanaItalic";
  src: local("VerdanaItalic"),
    url("./fonts/Verdana/verdana-italic.ttf") format("truetype");
}

@font-face {
  font-family: "VerdanaBold";
  src: local("VerdanaBold"),
    url("./fonts/Verdana/verdana-bold-3.ttf") format("truetype");
}

@font-face {
  font-family: "VerdanaBoldItalic";
  src: local("VerdanaBoldItalic"),
    url("./fonts/Verdana/verdana-bold-italic.ttf") format("truetype");
}

@font-face {
  font-family: "AmaticRegular";
  src: local("AmaticRegular"),
    url("./fonts/Amatic/amatic-regular.ttf") format("truetype");
}

@font-face {
  font-family: "AmaticBold";
  src: local("AmaticBold"),
    url("./fonts/Amatic/amatic-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Amatic";
  src: local("Amatic"),
    url("./fonts/Amatic/amatic-sc.ttf") format("truetype");
}