.divSidebar {
    top: 150px;
    border: 3px solid var(--primary-base-light2);;
    background-color: rgb(255, 255, 255, 0.4);
    position: absolute;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px 20px;
    padding-bottom: 40px;
    scale: 0.75
}

.nodeFaseExample {
    width: 12vw;
    height: 12vh;
    border: 1px solid var(--primary-base-dark2);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    font-weight: bold;
    background-color: #f5f8ff;
    padding: 10px;
    font-weight: 500;
    color:var(--primary-base-dark2);
  }

  .nodeInicioExample {
    width: 5vw;
    height: 5vh;
    border: 1px solid var(--primary-base-dark2);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: 50px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: #46ff46;

  }

  .nodeFimExample {
    width: 5vw;
    height: 5vh;
    border: 1px solid var(--primary-base-dark2);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: #ff3939;
    padding: 10px;
  }

  .nodeRaiaExample {
    width: 12vw;
    height: 12vh;
    border: 1px solid var(--primary-base-dark2);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    font-weight: bold;
    font-weight: 500;
  }

  .nodeRaiaSubExample {
    width: 12vw;
    height: 12vh;
    background-color: white;
    writing-mode: vertical-rl;
    text-align: center;
    padding: 4px;
  }

  .nodeCondicaoExample {
    width: 90px;
    height: 90px;
    rotate: 45deg;
    border: 1px solid d86f3e;
    box-shadow: 0 1px 3px var(--primary-base-dark2), 0 1px 2px var(--primary-base-dark2);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #f5f8ff;
    padding-top: 15px;
    font-weight: 500;
    color:var(--primary-base-dark2);
  }

  .nodeCondicaoExample p{
    rotate: -45deg;
  }