a {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.navigation-list-item {
  margin-right: 200px;
}
.navigation-list-item:first-child {
  margin-right: 15px;
}
.navigation-list-item:nth-child(3) {
  margin-right: 25px;
}
.navigation-list-item:last-child {
  margin-right: 0;
}

.navigation-service-icon {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
  margin: 0 auto 7px;
}
.navigation-service-icon-front {
  background-image: url(../../../components/icones/front.svg);
  size: 12px;
}
.navigation-service-icon-input {
  background-image: url(../../../components/icones/input.png);
}
.navigation-service-icon-grid {
  background-image: url(../../../components/icones/grid.png);
}

.main-content-orange-dark .navigation-service-icon-grid {
  background-image: url(../../../components/icones/grid-white.png);
}

.navigation-service-icon-table {
  background-image: url(../../../components/icones/table.svg);
}