.nodeFase {
  background-color: var(--primary-base);
  border: 1px solid var(--primary-base-dark2);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 10px 10px 15px;
  color: var(--primary-base);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.nodeFase:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.nodeFaseIcon {
  color: white;
  margin: 0 8px;
  cursor: pointer;
  font-size: 20px;
  transition: transform 0.2s ease, color 0.2s ease;
}

.nodeFaseIcon:hover {
  transform: scale(1.2);
  color: #814a86;
}

.nodeFaseInicial {
  background-color: #814a86;
  border: 1px solid #7b40a9;
  border-radius: 8px;
  color: #814a86;
  padding: 10px 10px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.nodeFaseInicial:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.nodeFaseIconInicial {
  color: white;
  font-size: 20px;
  margin: 0 8px;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
}

.nodeFaseIconInicial:hover {
  transform: scale(1.2);
  color: var(--primary-base);
}

.nodeFaseBody {
  border: 1px solid #fff;
  background-color: var(--primary-base-dark2); /* Alterar o fundo amarelo para vermelho */
  padding: 10px 15px;   
  border-radius: 8px;
  transition: border 0.2s ease, background-color 0.2s ease;
  text-align: center; /* Centralizar texto */
}

.nodeFaseBody:hover {
  border-color: white;
  background-color: white; /* Alterar a cor de hover para um vermelho mais claro */
} 

.nodeFaseContet {
  font-weight: bold;
  text-decoration: none;
  font-size: 12px;
}

.nodeFaseContet .sla {
  font-size: 12px;
  color: var(--primary-base);
  font-weight: 700;
  margin-top: 5px;
  display: block;

}

.customHandle {
  background-color: var(--primary-base-dark2);
  border: 1px solid var(--primary-base-dark2);
  border-radius: 50%;
}

.swal-popup-custom {
  background-color: var(--primary-base);
  border: 1px solid var(--primary-base-dark2);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 20px;
}