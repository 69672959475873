.content-login{
    margin: 0 auto;
    width: 80%;
    margin-top: 25%;

    img {
        display: block;
        margin: 30px auto;
    }

    input{
        background-color: #dddddd !important;
        border-color: #c4c4c4 !important;
        color: #000000 !important;
        transition: color 0.2s ease, background-color 0.2s ease !important;
    }

    .link-esqueci-minha-senha{
        margin-left: 5px;
        text-decoration: none;
        color: #d68500;
        
        &:hover{
            filter: brightness(0.8);
        }
    }
}

.logoxjur{
    height: 150px;
}

.logoxjur-lg{
    height: 300px;
}

.card-login{
    padding: 5%;
    background-color: white;
    filter: brightness(1.1);
    border-radius: 4%;
}