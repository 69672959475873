.nodeCondicaoBody {
  width: 70px; /* Diminuiu a largura */
  height: 70px; /* Diminuiu a altura */
  border: 1px solid var(--primary-base);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  rotate: 45deg;
  margin-left: 15px;
  margin-right: 15px;
  background-color: var(--primary-base); /* Set the background color to orange */
}

.icon-hover-effect {
  cursor: pointer;
  color: #ec9453;
  transition: transform 0.3s ease, color 0.3s ease;
}

.icon-hover-effect:hover {
  color: #7b40a3;
  transform: translateY(-3px);
}

.nodeCondicao{
  transform: translateY(-10px);
}

.nodeCondicaoIcon {
  color: var(--primary-base-dark2);
  font-size: 15px;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
}

.nodeCondicaoIcon:hover {
  transform: translateY(-10px);
  color: #7b40a3; /* Orange color on hover */
}

.nodeCondicaoContet {
  font-size: 8px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  rotate: -45deg;  
  pointer-events: none; 
  height: 100%; 
  width: 100%;
  background-color: #fff5e1; /* Set to transparent to show the orange background */
  color:var(--primary-base);

}


.nodeCondicaoInfo {
  padding-top: 2px;
  color: grey;
  font-size: 8px;
}

div.customHandle {
  width: 100%;
  height: 100%;
  background: blue;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  transform: none;
  border: none;
  opacity: 0;
}
