a {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.navigation-list-item {
  margin-right: 200px;
}

.navigation-list-item:first-child {
  margin-right: 15px;
}

.navigation-list-item:nth-child(3) {
  margin-right: 25px;
}

.navigation-list-item:last-child {
  margin-right: 0;
}

.navigation-service-icon {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  width: 33px;
  height: 33px;
  margin: 0 auto 7px;
}

.navigation-service-icon-start {
  background-image: url(../../../components/icones/star.svg);
  size: 5px;
}

.navigation-service-icon-startShow {
  background-image: url(../../../components/icones/star-show.svg);
  size: 5px;
}

.navigation-service-icon-garantia {
  background-image: url(../../../components/icones/garantia.png);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-bloqueio {
  background-image: url(../../../components/icones/bloqueio.png);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-obrigacao {
  background-image: url(../../../components/icones/obrigacao.png);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-arvore {
  background-image: url(../../../components/icones/arvore.png);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-despesa {
  background-image: url(../../../components/icones/despesa.png);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-decisao {
  background-image: url(../../../components/icones/decisao.png);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-proposta {
  background-image: url(../../../components/icones/proposta.png);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-valores {
  background-image: url(../../../components/icones/valores.png);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-dobrar {
  background-image: url(../../../components/icones/dobrar.png);
  width: 25px;
  height: 25px;
}


.navigation-service-icon-front {
  background-image: url(../../../components/icones/front.svg);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-logs {
  background-image: url(../../../components/icones/logs.png);
  width: 25px;
  height: 25px;
}

.navigation-service-icon-input {
  background-image: url(../../../components/icones/input.png);
  width: 25px;
  height: 25px;
}