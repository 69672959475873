.badge-success {
  background-color: #28a745;
  color: #fff;
}

.badge-warning {
  background-color: var(--bs-warning);
  color: var(--bs-text-warning);
}

.badge-danger {
  background-color: var(--bs-danger);
  color: #fff;
}

.badge-primary {
  background-color: var(--bs-primary);
  color: #fff;
}

.badge-info {
  background-color: var(--bs-info);
  color: var(--bs-text-info);
}

.badge {
  letter-spacing: 0 !important;
  font-size: 10px !important;
  border-radius: 4px !important;
}