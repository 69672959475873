.modal-body table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

.modal-body th {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid var(--primary-base2);
  font-size: large;
}

.modal-body td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: var(--primary-base2);
  font-size: medium;
}

.modal-body input {
  width: "20px";
  height: "20px";
  border-color: var(--primary-base2);
}

.modal-footer {
  margin: 0 auto;
}
