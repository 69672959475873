body {
  font-size: 0.875rem;
  background: linear-gradient(0deg, var(--primary-base), var(--secondary-base));
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.navigation-service-icon-startFavorite {
  background-image: url(../components/icones/star-show.svg);
}

.dropdown-toggle::after {
  display: none !important; 
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
}

.bg-orange {
  background-color: var(--primary-base) !important;
  // background: rgba(110, 61, 144, 0.8);
}

.search-buttom-margin {
  margin-top: 28px !important;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

@media (max-width: 768px) {
  .esconder-mobile {
    display: none !important;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 400;
  color: white;
  border-radius: 15px 0px 0px 15px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: red;
}

.sidebar .nav-link.active {
  color: black;
  border-radius: 15px 0px 0px 15px;
}

.sidebar .nav-link:hover {
  background-color: var(--primary-base-light1);
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: var(--primary-base);
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25); */
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}



.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.search-dark #pesquisaGlobal{
  background-color: #adadad;
}

.search-dark #pesquisaGlobal:focus{
  background-color: #dad9d9;
}

.text-orange {
  color: var(--primary-base);
}

.text-white {
  color: white;
}

.btn-orange {
  color: #fff;
  background-color: var(--primary-base);
  border-color: var(--primary-base);
}

.btn-orange:hover {
  color: #fff;
  background-color: var(--primary-base-light3);
  border-color: var(--primary-base-light3);
}

.required::after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: red;
  padding-left: 0.25rem;
  font-weight: bold;
}

.rdt_Pagination {
  justify-content: center !important;
}

.avatar-orange {
  cursor: pointer;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: rgb(255, 255, 255);
  background-color: rgb(189, 189, 189);
}

.icone-notificacao-orange {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
}

.icone-notificacao-orange > svg {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: white;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.icone-notificacao-orange > span {
  display: flex;
  flex-flow: row wrap;
  -webkit-box-pack: center;
  place-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  min-width: 20px;
  line-height: 1;
  padding: 0px 6px;
  height: 20px;
  border-radius: 10px;
  z-index: 1;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(211, 47, 47);
  color: rgb(255, 255, 255);
  top: 0px;
  right: 0px;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}

.menu-usuario-orange {
  display: flex;
  align-items: center;
}

.pro-sidebar-layout::-webkit-scrollbar,
.main-orange::-webkit-scrollbar-thumb,
.main-content-orange::-webkit-scrollbar-thumb {
  display: none;
}

.main-orange {
  background: linear-gradient(90deg, var(--primary-base), var(--secondary-base));
  padding: 20px 0 0 0 !important;
  flex-grow: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  overflow-y: auto !important;
  position: relative !important;
  height: 100% !important;
  // background: rgba(110, 61, 144, 0.8);
  // background: linear-gradient(100deg, var(--primary-base), #6E3D90);
  background: linear-gradient(90deg, var(--primary-base), var(--secondary-base));
}

.dropdown-profile {
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: var(--primary-base);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-weight: 600;

  &:hover {
    background-color: var(--primary-base);
    color: white;
    // filter: brightness(0.5)
  }
}

.background-orange {
  background-color: var(--primary-base) !important;
}

// Adição de css para tratar os links e deixá-los com branco
a.linkVisitado:visited {
  color: white;
  text-decoration: none;
}

/*
* XSigner
*/

@media screen and (max-width: 1100px) and (min-width: 992px) {
  .form-buscar-envelope > * {
    padding-right: calc(var(--bs-gutter-x) * 0.4);
    padding-left: calc(var(--bs-gutter-x) * 0.4);
  }
  .form-control {
    .react-daterange-picker__inputGroup {
      min-width: calc((4px * 3) + 0.54em * 3 + 0.217em * 2);
      font-size: 0.85em;
    }
    .react-daterange-picker__range-divider {
      flex: 1;
      padding-right: calc(0rem);
    }
    .react-daterange-picker__clear-button {
      padding: 4px 0px;
      svg {
        width: 0.75em;
        height: 0.75em;
      }
    }
  }
}

.form-control {
  .react-daterange-picker__wrapper {
    border: none;
    padding: 0.3rem 0.25rem;
    margin: 0;
  }
  .react-daterange-picker__range-divider {
    flex: 1;
    //padding-right: calc(.54rem);
  }
}

@keyframes pulsing {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
}


.heartBeat {
  animation: heartBeat 1.0s linear infinite;
}

.envelope-barra-lateral {
  flex: 1 0 330px;
  max-width: 100%;
  margin-bottom: 2rem;
}

.envelope-documentos {
  flex: 1 0 790px;
  max-width: 100%;

  .documento-controles {
    max-width: 790px;
  }

  .pdf-documento-container {
    max-width: 790px;
    width: 100%;
    margin: 0 auto;

    &:fullscreen {
      overflow: auto;
    }

    .sair-fullscreen {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      color: #fff;
      z-index: 99999;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      text-align: center;
      font-size: 1.2rem;
      svg {
        stroke-width: 0.075em;
        background-color: var(--primary-base);
        margin: 0.5rem;
        border-radius: 50%;
        width: 2.25rem;
        height: 2.25rem;
        padding: 0.5rem;
      }

      &:fullscreen {
        display: block;
      }
    }

    @media all and (display-mode: fullscreen) {
      & {
        overflow: auto;
        padding: 2rem;
        background-color: #fff5f594;
      }
    }
    @media (max-width: 767.98px) and (display-mode: fullscreen) {
      & {
        overflow: auto;
        padding: 0.25rem;
        background-color: #fff5f594;
      }
    }
  }

  .pdf-documento-tela-cheia {
    @media all and (display-mode: fullscreen) {
      & {
        * {
          display: none;
        }
        display: none;
      }
    }

    @media printing {
      & {
        * {
          display: none;
        }
        display: none;
      }
    }
    &:fullscreen {
      display: none;
    }

    float: left;
    text-decoration: none;
    color: #428bca;
    text-decoration: none;
    font-size: 0.75rem;
    font-weight: bolder;
    padding-bottom: 0.25rem;
    cursor: pointer;
    svg {
      stroke-width: 0.6;
      display: inline-block;
      margin: 0 0.2rem 0.25rem 0;
    }
  }

  .pdf-documento-download {
    @extend .pdf-documento-tela-cheia;
    float: right;
    margin-right: 0.2rem;
    svg {
      display: inline-block;
      margin: 0 0 0.25rem 0.2rem;
    }
  }

  .pdf-documento-tela-cheia-imprimir {
    display: none;

    @media all and (display-mode: fullscreen) {
      & {
        * {
          display: block;
        }
        display: block;
      }
    }

    @media printing {
      & {
        * {
          display: none;
        }
        display: none;
      }
    }

    position: fixed;
    top: 0;
    right: 1rem;
    z-index: 99999;

    text-decoration: none;
    color: #fff;
    background-color: var(--primary-base-light3);
    padding: 0.25rem 0.5rem;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    display: inline-block;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-base-dark1);
      color: #fff9;
    }
  }

  .pdf-pagina-conteiner {
    box-shadow: 0px 0px 4px 1px #343a4042;
    position: relative;
    width: 100%;
    margin-bottom: 2em;
    background: white;

    .capa {
      backdrop-filter: blur(0px);
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      i {
        display: none;
      }
    }
    &.error {
      .capa {
        backdrop-filter: blur(20px);
        i {
          display: flex;
          margin: 0 auto;
          width: fit-content;
          height: fit-content;
          background-color: transparent;
          color: #f27474;
          font-size: 7rem;
        }
        h6 {
          text-align: center;
          a {
            cursor: pointer;
          }
        }
      }
    }
    &.carregando {
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: 2rem;
        left: 2rem;
        line-height: 5rem;
        text-justify: distribute;
        overflow: hidden;
        width: calc(100% - 5rem);
        height: 100%;
      }

      .capa {
        backdrop-filter: blur(20px);
        i {
          display: flex;
          margin: 0.5rem auto;
          width: fit-content;
          height: fit-content;
          background-color: transparent;
          color: #0005;
          font-size: 5rem;
          animation: heartBeat 1s infinite;
        }
      }
    }
  }

  .documento-conteiner {
    @extend .pdf-pagina-conteiner;

    word-break: break-all;
    padding: 30px;
    background: white;
    margin: auto;
    margin-bottom: 2rem;

    * {
      all: unset;
    }

    html,
    bodyaddress,
    blockquote,
    body,
    dd,
    div,
    dl,
    dt,
    fieldset,
    form,
    frame,
    frameset,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    noframes,
    ol,
    p,
    ul,
    center,
    dir,
    hr,
    menu,
    pre {
      display: block;
      unicode-bidi: embed;
    }
    li {
      display: list-item;
      list-style-type: disc;
    }
    head {
      display: none;
    }
    table {
      display: table;
    }
    img {
      width: 100%;
    }
    tr {
      display: table-row;
    }
    thead {
      display: table-header-group;
    }
    tbody {
      display: table-row-group;
    }
    tfoot {
      display: table-footer-group;
    }
    col {
      display: table-column;
    }
    colgroup {
      display: table-column-group;
    }
    th {
      display: table-cell;
    }
    td {
      display: table-cell;
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      padding: 0.2em 0.5em;
    }
    caption {
      display: table-caption;
    }
    th {
      font-weight: bolder;
      text-align: center;
    }
    caption {
      text-align: center;
    }
    body {
      margin: 8px;
    }
    h1 {
      font-size: 2em;
      margin: 0.67em 0;
    }
    h2 {
      font-size: 1.5em;
      margin: 0.75em 0;
    }
    h3 {
      font-size: 1.17em;
      margin: 0.83em 0;
    }
    h4,
    p,
    blockquote,
    ul,
    fieldset,
    form,
    ol,
    dl,
    dir,
    menu {
      margin: 1.12em 0;
    }
    h5 {
      font-size: 0.83em;
      margin: 1.5em 0;
    }
    h6 {
      font-size: 0.75em;
      margin: 1.67em 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    b,
    strong {
      font-weight: bolder;
    }
    blockquote {
      margin-left: 40px;
      margin-right: 40px;
    }
    i,
    cite,
    em,
    var,
    address {
      font-style: italic;
    }
    pre,
    tt,
    code,
    kbd,
    samp {
      font-family: monospace;
    }
    pre {
      white-space: pre;
    }
    button,
    textarea,
    input,
    select {
      display: inline-block;
    }
    big {
      font-size: 1.17em;
    }
    small,
    sub,
    sup {
      font-size: 0.83em;
    }
    sub {
      vertical-align: sub;
    }
    sup {
      vertical-align: super;
    }
    table {
      border-spacing: 2px;
    }
    thead,
    tbody,
    tfoot {
      vertical-align: middle;
    }
    td,
    th,
    tr {
      vertical-align: inherit;
    }
    s,
    strike,
    del {
      text-decoration: line-through;
    }
    hr {
      border: 1px inset;
    }
    ol,
    ul,
    dir,
    menu,
    dd {
      margin-left: 40px;
    }
    ol {
      list-style-type: decimal;
    }
    ol ul,
    ol ul,
    ul ol,
    ul ol,
    ul ul,
    ul ul,
    ol ol,
    ol ol {
      margin-top: 0;
      margin-bottom: 0;
    }
    u,
    ins {
      text-decoration: underline;
    }
    br:before {
      content: "\A";
      white-space: pre-line;
    }
    center {
      text-align: center;
    }
    :link,
    :visited {
      text-decoration: underline;
    }
    :focus {
      outline: thin dotted invert;
    }
    /* Begin bidirectionality settings (do not change) */
    BDO[DIR="ltr"] {
      direction: ltr;
      unicode-bidi: bidi-override;
    }
    BDO[DIR="rtl"] {
      direction: rtl;
      unicode-bidi: bidi-override;
    }
    *[DIR="ltr"] {
      direction: ltr;
      unicode-bidi: embed;
    }
    *[DIR="rtl"] {
      direction: rtl;
      unicode-bidi: embed;
    }
    @media print {
      h1 {
        page-break-before: always;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        page-break-after: avoid;
      }
      ul,
      ol,
      dl {
        page-break-before: avoid;
      }
    }
  }
}

.pdf-documento-baixar-tudo {
  background-color: transparent;
  border: 1px solid #428bca;
  border-radius: 0.25rem;
  color: #428bca;
  cursor: pointer;
  float: right;
  font-size: 0.7rem;
  font-weight: bolder;
  line-height: 1.5;
  padding: 0.25rem 0.4rem;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #428bca;
    color: #fff;
  }
  svg {
    stroke-width: 0.6;
    display: inline-block;
    margin: 0 0.2rem 0.18rem 0.25rem;
  }
}

.badge-success {
  background-color: #28a745;
  color: #fff;
}

.public-xsign {
  .conteudo {
    margin: 2rem auto;
    position: relative;
    /* text-align: center; */
    padding: 5rem;
    max-width: calc(100% - 10rem);
    width: fit-content;
    background: white;
    border-radius: 1rem;
    text-rendering: optimizelegibility;
  }

  @media screen and (max-width: 991px) {
    .conteudo {
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 575px) {
  .assinatura {
    width: calc(89.64375vw) !important;
    height: calc(89.64375vw * 0.30232558139) !important;
  }
}



.pdf-documento-container:fullscreen {
  .sair-fullscreen {
    display: block;
  }
  .pdf-documento-tela-cheia {
    display: none;
  }
  .pdf-documento-download {
    display: none;
  }
}

.text-orange-grad{  
  color: var(--primary-base);
  @supports(-webkit-background-clip: text) {
    background: linear-gradient(90deg, var(--primary-base), var(--secondary-base));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.btn-orange-grad{
  background: linear-gradient(90deg, var(--primary-base), var(--secondary-base));
  border-image-source: linear-gradient(90deg, rgb(173, 104, 54), rgb(83, 43, 112));
  border-image-slice: 1;
  color: white;
}


/* Define a cor preta para o texto do MultiSelect */
.multi-select {
  color: black !important;
}
 
/* Aplique a cor preta ao texto das opções e ao label */
.multi-select__option,
.multi-select__option--selected,
.multi-select__label {
  color: black !important;
}
 
/* Se necessário, ajuste o fundo para branco */
.multi-select__option {
  background-color: white !important;
}

.rmsc{
  color: pink;
}