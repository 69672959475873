.nodeInitBody {
  width: 90px;
  height: 35px;
  border: 1px solid rgb(85, 85, 85);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.nodeInitIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff; /* Cor do ícone, ajuste conforme necessário */
  font-size: 10px; /* Tamanho do ícone, ajuste conforme necessário */
  background-color: rgb(92, 92, 92);
  width: 20px;
  height: 25px;
  border-radius: 5px;
  padding-bottom: 10px;
  margin-bottom: -10px;
  cursor: pointer;
}

.nodeInitContet{
  padding-top: 12px;
  font-size: 15px;
  font-weight: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  flex-direction: column;
}

.nodeInitInfo{
  padding-top: 2px;
  color: grey;
  font-size: 8px;
}

div.customHandle {
  width: 100%;
  height: 100%;
  background: blue;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  transform: none;
  border: none;
  opacity: 0;
}
