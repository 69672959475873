.modal-size {
  max-width: 90% !important;
}

.modal-size-height {
  min-height: 100%;
  max-height: 100%;
}

.modal-container {
  padding: 20px 0;
  width: 100%;
  overflow-x: scroll;
}

.modal-custom-header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  > button {
    margin-right: 12px;

    > svg {
      margin-left: 4px;
    }
  }
}

@media print {
  .modal-container {
    width: 100%;
    height: 100% !important;
    overflow: visible;
  }
}

