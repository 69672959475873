.pro-menu-item{
    border-radius: 15px 0 0 15px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover{
    color: white !important;
    background-color: var(--primary-base-light1) !important;
    border-radius: 15px 0 0 15px !important;
}

.pro-menu-item.active{
    background-color: white !important;
    color: var(--primary-base) !important;
}


.navbar-brand-orange{
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: var(--primary-base);
}

.navbar{
    justify-content: center !important;
}

.main-content-orange {
    border: 10px solid var(--primary-base-light2);
    border-left: 0px;
    border-radius: 30px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255) !important;
    overflow-y: auto !important;
    padding: 24px !important;
    height: 100% !important;
  }

.main-content-orange-dark {
    border: 10px solid var(--primary-base-light2);
    border-left: 0px;
    border-radius: 30px;
    box-sizing: border-box;
    background-color: rgb(40,36,36) !important;
    overflow-y: auto!important;
    padding: 24px !important;
    height: 100% !important;
    
}

.main-content-orange svg {
    /* color: white; */
    /* color: var(--secondary-base); */
}
.main-content-orange-dark svg {
    color: white;
    /* color: var(--secondary-base); */
}


.main-content-orange-dark p{
    color:white  !important;  
}

.main-content-orange-dark h1{
    color:white !important;  
}

.main-content-orange-dark span{
    color:white !important;  
}

.main-content-orange-dark label{
    color:white !important;  
}

.grid-padrao-dark *{
    background-color: rgb(82, 79, 79) !important;
    color:white !important;  
}

.main-content-orange-dark .rdt_Table p {
    color: var(--primary-base) !important;

}
.popover-body svg {
    color: var(--primary-base) !important;
}

.remove-padding {
    padding: 0 !important;
}

.pro-inner-list-item{
    border-radius: 20px !important;
}

.pro-sidebar{
    width: 330px !important;
    min-width: 330px !important; /*Alterado para evitar bugs com elementos fixados*/
}

.pro-sidebar.collapsed {
    width: 80px !important;
    min-width: 80px !important;
}

.pro-sidebar.collapsed .navbar-brand-orange > img {
    width: 60px !important;
}

@media (max-width: 768px)
{
    .main-content-orange{
        border-left: 10px solid var(--primary-base-light2) !important;
    }

    .pro-sidebar.md {
        left: -330px !important;
    }

    .pro-sidebar.md.toggled {
        left: 0 !important;
    }
}

.closemenu {color: rgb(255, 255, 255);position: absolute;right: 0;z-index: 9999;line-height: 20px;border-radius: 50%;font-weight: bold;font-size: 22px;top: 55px;cursor: pointer;}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    background-color: var(--primary-base-dark3) !important;
    border-radius: 20px !important;
}

.main-content-orange-dark .dropdown-container *{
    color: #4b4b4b !important;
  }